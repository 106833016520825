export default {
  namespaced: true,
  state: {
    customerName: '',
    offeringNumber: '',
    vehicle: {
      year: '',
      make: 'Ford',
      model: '',
      vin: '',
    },
    equipments: {
      factoryInvoice: '',
      list: [],
      addEquipExcluded: '',
      capitalizedInitialTitleTax: '',
      capitalizedInitialTitleNoTax: '',
      upFrontTaxesPaidByDealer: '',
      areTaxesCapitalized: '',
    },
    upFront: {
      localTax: '',
      stateTax: '',
      fetTax: '',
      otherTax: '',
    },
    taxes: {
      softAddOnProducts: [],
      maintenancePlan: '',
      dropdownMaintenancePlan: {
        label: '',
        value: '',
      },
      serviceContract: '',
      dropdownServiceContract: {
        label: '',
        value: '',
      },
      gap: '',
      dropdownGap: {
        label: '',
        value: '',
      },
      paintlessDentRepair: '',
      tireWheel: '',
      windshield: '',
      bundledProduct: '',
      vehicleMarkup: '',
      capitalizedCostReduction: '',
      rebates: '',
      tradeAllowance: '',
      tradeOwed: '',
      residual: '',
      customerLeaseRate: '',
      dealerParticipation: '',
      term: '',
      manufacturingRepurchase: '',
      supplementDate: '',
      paymentDueDay: '',
      paymentTiming: {
        label: '',
        value: '',
      },
      vehicleMSRP: '',
      cityTax: '',
      stateTax: '',
      countyTax: '',
      otherTax: '',
    },
  },
  getters: {
    customerName: (state) => state.customerName,
    offeringNumber: (state) => state.offeringNumber,
    equipments: (state) => state.equipments,
    upFront: (state) => state.upFront,
    vehicle: (state) => state.vehicle,
    taxes: (state) => state.taxes,
  },
  mutations: {
    /**
     * Set Customer Name
     * @param {String} name - Name of Customer
     */
    setCustomerName: (state, name) => {
      // Checks if name is valid
      if (state.customerName === name) return;
      state.customerName = name;
    },

    /**
       * Set Offering Number
       * @param {String} name - Name of Dealer
       */
    setOfferingNumber: (state, name) => {
      // Checks if name is valid
      if (state.offeringNumber === name) return;
      state.offeringNumber = name;
    },

    /**
     * Set vehicle information
     * @param {String} year - Year of vehicle
     * @param {String} make - Make of vehicle
     * @param {String} model - Model of vehicle
     * @param {String} vin - Net Cap Cost
     */
    setVehicle: (
      state,
      {
        year,
        make,
        model,
        vin,
      },
    ) => {
      state.vehicle = {
        year,
        make,
        model,
        vin,
      };
    },

    /**
     * Set equipments information
     * @param {String} factoryInvoice - Factory Invoice
     * @param {Array} list - List
     * @param {String} addEquipExcluded - Add Equip Excluded
     * @param {String} capitalizedInitialTitleTax - Model of vehicle
     * @param {String} capitalizedInitialTitleNoTax - Net Cap Cost
     * @param {String} upFrontTaxesPaidByDealer - Up Front Taxes Paid By Dealer
     * @param {String} areTaxesCapitalized - Are Taxes Capitalized
     */

    setEquipments: (
      state,
      {
        factoryInvoice,
        list,
        addEquipExcluded,
        capitalizedInitialTitleTax,
        capitalizedInitialTitleNoTax,
        upFrontTaxesPaidByDealer,
        areTaxesCapitalized,
      },
    ) => {
      state.equipments = {
        factoryInvoice,
        list,
        addEquipExcluded,
        capitalizedInitialTitleTax,
        capitalizedInitialTitleNoTax,
        upFrontTaxesPaidByDealer,
        areTaxesCapitalized,
      };
    },

    /**
     * Set Up Front information
     * @param {String} localTax - Local Tax
     * @param {String} stateTax - State Tax
     * @param {String} fetTax - Fet Tax
     * @param {String} otherTax - Other Tax
     */

    setUpFront: (
      state,
      {
        localTax,
        stateTax,
        fetTax,
        otherTax,
      },
    ) => {
      state.upFront = {
        localTax,
        stateTax,
        fetTax,
        otherTax,
      };
    },

    /**
     * Set Taxes information
     * @param {String} maintenancePlan - Maintenance Plan
     * @param {String} dropdownMaintenancePlan - Dropdown Maintenance Plan
     * @param {String} serviceContract - Service Contract
     * @param {String} dropdownServiceContract - Dropdown Service Contract
     * @param {String} gap - GAP
     * @param {String} dropdownGap - Dropdown GAP
     * @param {String} paintlessDentRepair - Paintless Dent Repair
     * @param {String} tireWheel - Tire Wheel
     * @param {String} windshield - Windshield
     * @param {String} bundledProduct - Bundled Product
     * @param {String} vehicleMarkup - Vehicle Markup
     * @param {String} capitalizedCostReduction - Capitalized Cost Reduction
     * @param {String} rebates - Rebates
     * @param {String} tradeAllowance - Trade Allowance
     * @param {String} tradeOwed - Trade Owed
     * @param {String} residual - Residual
     * @param {String} customerLeaseRate - Customer Lease Rate
     * @param {String} dealerParticipation - Dealer Participation
     * @param {String} term - Term
     * @param {String} manufacturingRepurchase - Manufacturing Repurchase
     * @param {String} supplementDate - Supplement Date
     * @param {String} paymentDueDay - Payment Due Day
     * @param {String} paymentTiming - Payment Timing
     * @param {Object} taxes.vehicleMSRP - Data of Vehicle
     * @param {String} taxes.cityTax - City Tax
     * @param {String} taxes.stateTax - Sate Tax
     * @param {String} taxes.countyTax - County Tax
     * @param {String} taxes.otherTax - Other Tax
     */

    setTaxes: (
      state,
      {
        softAddOnProducts,
        maintenancePlan,
        dropdownMaintenancePlan,
        serviceContract,
        dropdownServiceContract,
        gap,
        dropdownGap,
        paintlessDentRepair,
        tireWheel,
        windshield,
        bundledProduct,
        vehicleMarkup,
        capitalizedCostReduction,
        rebates,
        tradeAllowance,
        tradeOwed,
        residual,
        customerLeaseRate,
        dealerParticipation,
        term,
        manufacturingRepurchase,
        supplementDate,
        paymentDueDay,
        paymentTiming,
        vehicleMSRP,
        cityTax,
        stateTax,
        countyTax,
        otherTax,
      },
    ) => {
      state.taxes = {
        softAddOnProducts,
        maintenancePlan,
        dropdownMaintenancePlan,
        serviceContract,
        dropdownServiceContract,
        gap,
        dropdownGap,
        paintlessDentRepair,
        tireWheel,
        windshield,
        bundledProduct,
        vehicleMarkup,
        capitalizedCostReduction,
        rebates,
        tradeAllowance,
        tradeOwed,
        residual,
        customerLeaseRate,
        dealerParticipation,
        term,
        manufacturingRepurchase,
        supplementDate,
        paymentDueDay,
        paymentTiming,
        vehicleMSRP,
        cityTax,
        stateTax,
        countyTax,
        otherTax,
      };
    },

    /**
     * Clear all information from Capital Cost
     */
    setClearCapitalCost: (state) => {
      state.customerName = '';
      state.offeringNumber = '';
      state.vehicle.year = '';
      state.vehicle.make = 'Ford';
      state.vehicle.model = '';
      state.vehicle.vin = '';
      state.equipments.factoryInvoice = '';
      state.equipments.list = [];
      state.equipments.addEquipExcluded = '';
      state.equipments.capitalizedInitialTitleTax = '';
      state.equipments.capitalizedInitialTitleNoTax = '';
      state.equipments.upFrontTaxesPaidByDealer = '';
      state.equipments.areTaxesCapitalized = '';
      state.upFront.localTax = '';
      state.upFront.stateTax = '';
      state.upFront.fetTax = '';
      state.upFront.otherTax = '';
      state.taxes.maintenancePlan = '';
      state.taxes.dropdownMaintenancePlan = {
        label: '',
        value: '',
      };
      state.taxes.serviceContract = '';
      state.taxes.dropdownServiceContract = {
        label: '',
        value: '',
      };
      state.taxes.gap = '';
      state.taxes.dropdownGap = {
        label: '',
        value: '',
      };
      state.taxes.paintlessDentRepair = '';
      state.taxes.tireWheel = '';
      state.taxes.windshield = '';
      state.taxes.bundledProduct = '';
      state.taxes.vehicleMarkup = '';
      state.taxes.capitalizedCostReduction = '';
      state.taxes.rebates = '';
      state.taxes.tradeAllowance = '';
      state.taxes.tradeOwed = '';
      state.taxes.residual = '';
      state.taxes.customerLeaseRate = '';
      state.taxes.dealerParticipation = '';
      state.taxes.term = '';
      state.taxes.manufacturingRepurchase = '';
      state.taxes.supplementDate = '';
      state.taxes.paymentDueDay = '';
      state.taxes.paymentTiming = {
        label: '',
        value: '',
      };
      state.taxes.vehicleMSRP = '';
      state.taxes.cityTax = '';
      state.taxes.stateTax = '';
      state.taxes.countyTax = '';
      state.taxes.otherTax = '';
    },
  },
  actions: {
    /**
     * Update the Customer Name
     * @param {String} name - Name of Customer
     */
    updateCustomerName({ commit }, name) {
      commit('setCustomerName', name);
    },

    /**
     * Set Offering Number
     * @param {String} name - Name of Dealer
     */
    updateOfferingNumber({ commit }, name) {
      commit('setOfferingNumber', name);
    },

    /**
     * Set vehicle information
     * @param {Object} vehicle - Data of Vehicle
     * @param {String} vehicle.year - Year of vehicle
     * @param {String} vehicle.make - Make of vehicle
     * @param {String} vehicle.model - Model of vehicle
     * @param {String} vehicle.vin - Net Cap Cost
     */
    updateVehicle({ commit }, vehicle) {
      commit('setVehicle', vehicle);
    },

    /**
     * Set equipments information
     * @param {Object} equipments - Data of Equipments
     * @param {Array} equipments.factoryInvoice - Factory Invoice
     * @param {Array} equipments.list - List
     * @param {String} equipments.addEquipExcluded - Add Equip Excluded
     * @param {String} equipments.capitalizedInitialTitleTax - Model of vehicle
     * @param {String} equipments.capitalizedInitialTitleNoTax - Net Cap Cost
     * @param {String} equipments.upFrontTaxesPaidByDealer - Up Front Taxes Paid By Dealer
     * @param {String} equipments.areTaxesCapitalized - Are Taxes Capitalized
     */
    updateEquipments({ commit }, equipments) {
      commit('setEquipments', equipments);
    },

    /**
     * Set Up Front information
     * @param {Object} upFront - Data of Up Front
     * @param {String} upFront.localTax - Local Tax
     * @param {String} upFront.stateTax - State Tax
     * @param {String} upFront.fetTax - Fet Tax
     * @param {String} upFront.otherTax - Other Tax
     */
    updateUpFront({ commit }, upFront) {
      commit('setUpFront', upFront);
    },

    /**
     * Set Taxes information
     * @param {Object} taxes - Data of Taxes
     * @param {String} taxes.maintenancePlan - Maintenance Plan
     * @param {String} dropdownMaintenancePlan - Dropdown Maintenance Plan
     * @param {String} taxes.serviceContract - Service Contract
     * @param {String} dropdownServiceContract - Dropdown Service Contract
     * @param {String} taxes.gap - GAP
     * @param {String} dropdownGap - Dropdown GAP
     * @param {String} taxes.paintlessDentRepair - Paintless Dent Repair
     * @param {String} taxes.tireWheel - Tire Wheel
     * @param {String} taxes.windshield - Windshield
     * @param {String} taxes.bundledProduct - Bundled Product
     * @param {String} taxes.vehicleMarkup - Vehicle Markup
     * @param {String} taxes.capitalizedCostReduction - Capitalized Cost Reduction
     * @param {String} taxes.rebates - Rebates
     * @param {String} taxes.tradeAllowance - Trade Allowance
     * @param {String} taxes.tradeOwed - Trade Owed
     * @param {String} taxes.residual - Residual
     * @param {String} taxes.customerLeaseRate - Customer Lease Rate
     * @param {String} taxes.dealerParticipation - Dealer Participation
     * @param {String} taxes.term - Term
     * @param {String} taxes.manufacturingRepurchase - Manufacturing Repurchase
     * @param {String} taxes.supplementDate - Supplement Date
     * @param {String} taxes.paymentDueDay - Payment Due Day
     * @param {String} taxes.paymentTiming - Payment Timing
     * @param {Object} taxes.vehicleMSRP - Data of Vehicle
     * @param {String} taxes.cityTax - City Tax
     * @param {String} taxes.stateTax - Sate Tax
     * @param {String} taxes.countyTax - County Tax
     * @param {String} taxes.otherTax - Other Tax
     */
    updateTaxes({ commit }, taxes) {
      commit('setTaxes', taxes);
    },

    /**
     * Update All Capital Cost Form
     * @param {Object} data - Data of Capital Cost
     * @param {String} data.customerName - Name of Customer
     * @param {String} data.offeringNumber - Offering Number
     * @param {Object} data.equipments - Data of Equipments
     * @param {Object} data.upFront - Data of UpFront
     * @param {Object} data.vehicle - Data of Vehicle
     * @param {Object} data.taxes - Data of Taxes
     */
    updatecapitalCost({ commit }, data) {
      commit('setCustomerName', data.customerName);
      commit('setOfferingNumber', data.offeringNumber);
      commit('setEquipments', data.equipments);
      commit('setUpFront', data.upFront);
      commit('setVehicle', data.vehicle);
      commit('setTaxes', data.taxes);
    },

    /**
     * Clear All Capital Cost
     */
    clearCapitalCost({ commit }) {
      commit('setClearCapitalCost');
    },
  },
};
