<template>
  <div class="product-item">
    <InputCurrency
      :id="`capital-cost-product-item--${index}`"
      :name="`capital-cost-product-item--${index}`"
      :submitted="hasSubmitted"
      v-model:value="productCostValue"
      @hasError="validateField($event)"
      :required="productCostValue === ''"
    >{{ label }}</InputCurrency>
    <Dropdown
      :id="`capital-cost-product-dropdown-item-taxes--${index}`"
      :name="`capital-cost-product-dropdown-item-taxes--${index}`"
      :label="`${label} Taxes`"
      :submitted="hasSubmitted"
      :options="[
        {
          label: $t('tabs.capitalCost.sectionTaxes.dropdownOption1'),
          value: 'monthly',
        },
        {
          label: $t('tabs.capitalCost.sectionTaxes.dropdownOption2'),
          value: 'upfront',
        },
        {
          label: $t('tabs.capitalCost.sectionTaxes.dropdownOption3'),
          value: 'notax',
        },
      ]"
      :selectedOption="productTaxesValue"
      v-model:value="productTaxesValue"
      @select="productTaxesValue = $event"
      @hasError="validateField($event)"
      required
    ></Dropdown>
    <div class="product-item__button-wrapper field">
      <Button
        :id="`fds-table-equipment-delete-${index}`"
        label="Delete"
        :primary="false"
        isLink
        @onClick="$emit('deleteProduct')"
      />
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/form-elements/Dropdown.vue';
import InputCurrency from '@/components/form-elements/InputCurrency.vue';
import Button from '@/components/form-elements/Button.vue';

export default {
  name: 'FormAddOnProductItem',
  emits: [
    'deleteProduct',
    'update:productCost',
    'update:productTaxes',
  ],
  components: {
    Dropdown,
    InputCurrency,
    Button,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    hasSubmitted: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    productCost: {
      type: String,
      default: '',
    },
    productTaxes: {
      type: Object,
      required: true,
    },
    validateField: {
      type: Function,
      required: true,
    },
  },
  computed: {
    productCostValue: {
      get() {
        return this.productCost;
      },
      set(value) {
        this.$emit('update:productCost', value);
      },
    },
    productTaxesValue: {
      get() {
        return this.productTaxes;
      },
      set(value) {
        this.$emit('update:productTaxes', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  margin-bottom: fds-rem(40px);
  @include media('>=1024px'){
    grid-template-columns: repeat(3, 1fr);
  }
  &__button-wrapper {
    display: flex;
    align-items: flex-end;
  }
}
</style>
