<template>
  <section id="capital-cost" class="capital-cost">
    <form
      v-if="!showPDF"
      ref="capitalcostform"
      @submit.prevent="generatePDF()"
    >
      <div class="first-info fds-m--b-3">
        <Input
          id="capital-cost-costumer-name"
          class="field"
          name="capital-cost-costumer-name"
          :submitted="hasSubmitted"
          v-model:value="customerName"
          @hasError="validateField($event)"
          required
        >{{ componentText.customerNameLabel }}</Input>
        <Input
          id="capital-cost-offering-number"
          class="field"
          name="capital-cost-offering-number"
          :submitted="hasSubmitted"
          v-model:value="offeringNumber"
          v-maska="'################'"
          @hasError="validateField($event)"
          required
        >{{ componentText.offeringNumberLabel }}</Input>
      </div>

      <h3 class="capital-cost__subtitle fmc-type--subtitle1">
        {{ componentText.vehicle.title }}
      </h3>

      <div class="second-info fds-m--b-3">
        <Input
          id="capital-cost-vehicle-year"
          class="field"
          name="capital-cost-vehicle-year"
          :submitted="hasSubmitted"
          minlength="4"
          v-model:value="vehicle.year"
          v-maska="'####'"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.yearLabel }}</Input>
        <Input
          id="capital-cost-vehicle-make"
          class="field"
          name="capital-cost-vehicle-make"
          :submitted="hasSubmitted"
          v-model:value="vehicle.make"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.makeLabel }}</Input>
        <Input
          id="capital-cost-vehicle-model"
          class="field"
          name="capital-cost-vehicle-model"
          :submitted="hasSubmitted"
          v-model:value="vehicle.model"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.modelLabel }}</Input>
        <Input
          id="capital-cost-vehicle-vin"
          class="field"
          name="capital-cost-vehicle-vin"
          :submitted="hasSubmitted"
          v-model:value="vehicle.vin"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.vinLabel }}</Input>
      </div>

      <h3 class="capital-cost__subtitle fmc-type--subtitle1">
        {{ componentText.equipments.title }}
      </h3>
      <div class="third-info fds-m--b-3">
        <EquipmentTable
          class="fds-m--b-4"
          :listOfEquipments="equipments.list"
          :submitted="hasSubmitted"
          @equipments="equipments.list = $event"
          @hasError="validateField($event)"
        />
        <InputCurrency
          id="capital-cost-factory-invoice"
          class="field"
          name="capital-cost-factory-invoice"
          :submitted="hasSubmitted"
          v-model:value="equipments.factoryInvoice"
          @hasError="validateField($event)"
          required
        >{{ componentText.equipments.factoryInvoice }}</InputCurrency>
        <InputCurrency
          id="capital-cost-add-equip-excluded"
          class="field"
          name="capital-cost-add-equip-exclude"
          :submitted="hasSubmitted"
          v-model:value="equipments.addEquipExcluded"
          @hasError="validateField($event)"
          required
        >{{ componentText.equipments.addEquipExcludedLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-capitalized-initial-title-tax"
          class="field"
          name="capital-cost-capitalized-initial-title-tax"
          :submitted="hasSubmitted"
          :infoText="componentText.equipments.capitalizedInitialTitleInfoTax"
          v-model:value="equipments.capitalizedInitialTitleTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.equipments.capitalizedInitialTitleLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-capitalized-initial-title-notax"
          class="field"
          name="capital-cost-capitalized-initial-title-notax"
          :submitted="hasSubmitted"
          :infoText="componentText.equipments.capitalizedInitialTitleInfoNoTax"
          v-model:value="equipments.capitalizedInitialTitleNoTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.equipments.capitalizedInitialTitleLabel }}</InputCurrency>
        <div class="field radios-flex">
          <Radio
            id="capital-cost-up-front-taxes-paid-by-dealer"
            name="capital-cost-up-front-taxes-paid-by-dealer"
            :options="[
              {
                id: 'capital-cost-up-front-taxes-paid-by-dealer-yes',
                label: 'Yes',
                isChecked: equipments.upFrontTaxesPaidByDealer === 'Yes',
                isDisabled: false,
              },
              {
                id: 'capital-cost-up-front-taxes-paid-by-dealer-no',
                label: 'No',
                isChecked: equipments.upFrontTaxesPaidByDealer === 'No',
                isDisabled: false,
              },
            ]"
            :submitted="hasSubmitted"
            @input="equipments.upFrontTaxesPaidByDealer = $event"
            @hasError="validateField($event)"
            required
          >
            {{ componentText.equipments.upFrontTaxesPaidByDealer }}
          </Radio>
        </div>
        <div class="field radios-flex">
          <Radio
            id="capital-cost-are-taxes-capitalized"
            name="capital-cost-are-taxes-capitalized"
            :options="[
              {
                id: 'capital-cost-are-taxes-capitalized-yes',
                label: 'Yes',
                isChecked: equipments.areTaxesCapitalized === 'Yes',
                isDisabled: false,
              },
              {
                id: 'capital-cost-are-taxes-capitalized-no',
                label: 'No',
                isChecked: equipments.areTaxesCapitalized === 'No',
                isDisabled: false,
              },
            ]"
            :submitted="hasSubmitted"
            @input="equipments.areTaxesCapitalized = $event"
            @hasError="validateField($event)"
            required
          >
            {{ componentText.equipments.areTaxesCapitalized }}
          </Radio>
        </div>
      </div>

      <h3 class="capital-cost__subtitle fmc-type--subtitle1">
        {{ componentText.upFront.title }}
      </h3>
      <div class="third-info fds-m--b-3">
        <InputCurrency
          id="capital-cost-up-front-local-tax"
          class="field"
          name="capital-cost-up-front-local-tax"
          :submitted="hasSubmitted"
          v-model:value="upFront.localTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.upFront.localTaxLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-up-front-state-tax"
          class="field"
          name="capital-cost-up-front-state-tax"
          :submitted="hasSubmitted"
          v-model:value="upFront.stateTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.upFront.stateTaxLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-up-front-fet-tax"
          class="field"
          name="capital-cost-up-front-fet-tax"
          :submitted="hasSubmitted"
          v-model:value="upFront.fetTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.upFront.fetTaxLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-up-front-other-tax"
          class="field"
          name="capital-cost-up-front-other-tax"
          :submitted="hasSubmitted"
          v-model:value="upFront.otherTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.upFront.otherTaxLabel }}</InputCurrency>
      </div>

      <h3 class="capital-cost__subtitle fmc-type--subtitle1">
        {{ componentText.taxes.title }}
      </h3>
      <div class="third-info">
        <InputCurrency
          id="capital-cost-maintenance-plan"
          class="field"
          name="capital-cost-maintenance-plan"
          :submitted="hasSubmitted"
          v-model:value="taxes.maintenancePlan"
          @hasError="validateField($event)"
          :required="taxes.serviceContract === ''"
        >{{ componentText.taxes.maintenancePlanLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-service-contract"
          class="field"
          name="capital-cost-service-contract"
          :submitted="hasSubmitted"
          v-model:value="taxes.serviceContract"
          @hasError="validateField($event)"
          :required="taxes.maintenancePlan === ''"
        >{{ componentText.taxes.serviceContractLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-gap"
          class="field"
          name="capital-cost-gap"
          :submitted="hasSubmitted"
          v-model:value="taxes.gap"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.gapLabel }}</InputCurrency>
        <Dropdown
          id="capital-cost-dropdown-maintenance"
          class="field"
          name="capital-cost-dropdown-maintenance"
          v-model:value="taxes.dropdownMaintenancePlan"
          :label="componentText.taxes.dropdownLabel1"
          :submitted="hasSubmitted"
          :options="[
            {
              label: componentText.taxes.dropdownOption1,
              value: 'monthly',
            },
            {
              label: componentText.taxes.dropdownOption2,
              value: 'upfront',
            },
            {
              label: componentText.taxes.dropdownOption3,
              value: 'notax',
            },
          ]"
          :selectedOption="taxes.dropdownMaintenancePlan"
          :required="taxes.maintenancePlan !== ''"
          @select="taxes.dropdownMaintenancePlan = $event"
          @hasError="validateField($event)"
        ></Dropdown>
        <Dropdown
          id="capital-cost-dropdown-service"
          class="field"
          name="capital-cost-dropdown-service"
          v-model:value="taxes.dropdownServiceContract"
          :label="componentText.taxes.dropdownLabel2"
          :submitted="hasSubmitted"
          :options="[
            {
              label: componentText.taxes.dropdownOption1,
              value: 'monthly',
            },
            {
              label: componentText.taxes.dropdownOption2,
              value: 'upfront',
            },
            {
              label: componentText.taxes.dropdownOption3,
              value: 'notax',
            },
          ]"
          :selectedOption="taxes.dropdownServiceContract"
          :required="taxes.serviceContract !== ''"
          @select="taxes.dropdownServiceContract = $event"
          @hasError="validateField($event)"
        ></Dropdown>
        <Dropdown
          id="capital-cost-dropdown-gap"
          class="field"
          name="capital-cost-dropdown-gap"
          :label="componentText.taxes.dropdownLabel3"
          :submitted="hasSubmitted"
          :options="[
            {
              label: componentText.taxes.dropdownOption1,
              value: 'monthly',
            },
            {
              label: componentText.taxes.dropdownOption2,
              value: 'upfront',
            },
            {
              label: componentText.taxes.dropdownOption3,
              value: 'notax',
            },
          ]"
          :selectedOption="taxes.dropdownGap"
          v-model:value="taxes.dropdownGap"
          @select="taxes.dropdownGap = $event"
          @hasError="validateField($event)"
          required
        ></Dropdown>
      </div>

      <FormAddOnProductItem
        v-for="item, index in taxes.softAddOnProducts"
        v-model:productCost="item.productCost"
        v-model:productTaxes="item.productTaxes"
        :key="JSON.stringify(item.product)"
        :index="index"
        :hasSubmitted="hasSubmitted"
        :label="item.product.label"
        :validateField="validateField"
        @deleteProduct="taxes.softAddOnProducts.splice(index, 1)"
      />

      <FormAddOnProducts
        :selectedProducts="taxes.softAddOnProducts"
        @addProduct="addProduct"
      />

      <h3 class="capital-cost__subtitle fmc-type--subtitle1">
        {{ componentText.taxes.title2 }}
      </h3>
      <div class="third-info fds-m--b-3">
        <InputCurrency
          id="capital-cost-vehicle-markup"
          class="field"
          name="capital-cost-vehicle-markup"
          :submitted="hasSubmitted"
          v-model:value="taxes.vehicleMarkup"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.vehicleMarkupLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-capitalized-cost-reduction"
          class="field"
          name="capital-cost-capitalized-cost-reduction"
          :submitted="hasSubmitted"
          v-model:value="taxes.capitalizedCostReduction"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.capitalizedCostReductionLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-rebates"
          class="field"
          name="capital-cost-rebates"
          :submitted="hasSubmitted"
          v-model:value="taxes.rebates"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.rebatesLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-trade-allowance"
          class="field"
          name="capital-cost-trade-allowance"
          :submitted="hasSubmitted"
          v-model:value="taxes.tradeAllowance"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.tradeAllowanceLabel }}</InputCurrency>
        <InputCurrency
          id="capital-cost-trade-owed"
          class="field"
          name="capital-cost-trade-owed"
          :submitted="hasSubmitted"
          v-model:value="taxes.tradeOwed"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.tradeOwedLabel }}</InputCurrency>
        <InputPercent
          id="capital-cost-residual"
          class="field"
          name="capital-cost-residual"
          :submitted="hasSubmitted"
          v-model:value="taxes.residual"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.residualLabel }}</InputPercent>
        <InputPercent
          id="capital-cost-customer-lease-rate"
          class="field"
          name="capital-cost-customer-lease-rate"
          :infoText="componentText.taxes.customerLeaseRateInfo"
          :submitted="hasSubmitted"
          v-model:value="taxes.customerLeaseRate"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.customerLeaseRateLabel }}</InputPercent>
        <InputPercent
          id="capital-cost-dealer-participation"
          class="field"
          name="capital-cost-dealer-participation"
          :submitted="hasSubmitted"
          v-model:value="taxes.dealerParticipation"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.dealerParticipationLabel }}</InputPercent>
        <Input
          id="capital-cost-term"
          class="field"
          name="capital-cost-term"
          :submitted="hasSubmitted"
          v-model:value="taxes.term"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.termLabel }}</Input>
        <div class="field radios-flex">
          <Radio
            id="capital-cost-manufacturing-repurchase"
            name="capital-cost-manufacturing-repurchase"
            :options="[
              {
                id: 'capital-cost-manufacturing-repurchase-yes',
                label: 'Yes',
                isChecked: taxes.manufacturingRepurchase === 'Yes',
                disabled: false,
              },
              {
                id: 'capital-cost-manufacturing-repurchase-no',
                label: 'No',
                isChecked: taxes.manufacturingRepurchase === 'No',
                disabled: false,
              },
            ]"
            :submitted="hasSubmitted"
            @input="taxes.manufacturingRepurchase = $event"
            @hasError="validateField($event)"
            required
          >
            {{ componentText.taxes.manufacturingRepurchase }}
          </Radio>
        </div>
        <Datepicker
          class="field field-date"
          id="capital-cost-supplement-date"
          name="capital-cost-supplement-date"
          :label="componentText.taxes.supplementDateLabel"
          :setDate="taxes.supplementDate"
          :minDate="new Date()"
          :submitted="hasSubmitted"
          required
          @hasError="validateField($event)"
          @select="taxes.supplementDate = $event"
        />
        <Input
          id="capital-cost-payment-due-day"
          class="field"
          name="capital-cost-payment-due-day"
          pattern="^(?:[123456789]|0[1-9]|1[0-9]|2[0-9]|3[01]?)$"
          errorText="Invalid day"
          :submitted="hasSubmitted"
          minlength="4"
          v-model:value="taxes.paymentDueDay"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.paymentDueDayLabel }}</Input>
        <Dropdown
          id="capital-cost-payment-timing"
          class="field"
          name="capital-cost-payment-timing"
          :label="componentText.taxes.paymentTimingLabel"
          :submitted="hasSubmitted"
          :options="[
            {
              label: componentText.taxes.paymentTimingOption1,
              value: 'advance',
            },
            {
              label: componentText.taxes.paymentTimingOption2,
              value: 'arrears',
            },
          ]"
          :selectedOption="taxes.paymentTiming"
          v-model:value="taxes.paymentTiming"
          @select="taxes.paymentTiming = $event"
          @hasError="validateField($event)"
          required
        ></Dropdown>
        <InputCurrency
          id="capital-cost-vehicle-msrp"
          class="field"
          name="capital-cost-vehicle-msrp"
          :submitted="hasSubmitted"
          v-model:value="taxes.vehicleMSRP"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.vehicleMSRPLabel }}</InputCurrency>
        <InputPercent
          id="capital-cost-taxes-city-tax"
          class="field"
          name="capital-cost-taxes-city-tax"
          :submitted="hasSubmitted"
          v-model:value="taxes.cityTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.cityTaxLabel }}</InputPercent>
        <InputPercent
          id="capital-cost-taxes-state-tax"
          class="field"
          name="capital-cost-taxes-state-tax"
          :submitted="hasSubmitted"
          v-model:value="taxes.stateTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.stateTaxLabel }}</InputPercent>
        <InputPercent
          id="capital-cost-taxes-county-tax"
          class="field"
          name="capital-cost-taxes-county-tax"
          :submitted="hasSubmitted"
          v-model:value="taxes.countyTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.countyTaxLabel }}</InputPercent>
        <InputPercent
          id="capital-cost-taxes-other-tax"
          class="field"
          name="capital-cost-taxes-other-tax"
          :submitted="hasSubmitted"
          v-model:value="taxes.otherTax"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.otherTaxLabel }}</InputPercent>
        <InputPercent
          id="capital-cost-taxes-total-tax"
          class="field"
          name="capital-cost-taxes-total-tax"
          :submitted="hasSubmitted"
          v-model:value="taxesTotalTax"
          @hasError="validateField($event)"
          disabled
        >{{ componentText.taxes.totalTaxLabel }}</InputPercent>
      </div>

      <div class="third-info">
        <Button
          id="capital-cost-generate-pdf"
          class="fds-m--b-3"
          :type="'submit'"
          :label="componentText.buttonGeneratePDF"
          icon="next"
          @onClick="generatePDF()"
        />
      </div>
    </form>
    <div class="pdf" v-if="showPDF">
      <GeneratePDF />
      <Button
        id="capital-cost-return-to-form"
        class="fds-m--t-3"
        :type="'submit'"
        :label="componentText.buttonCloseAndReturn"
        icon="next"
        @onClick="showPDF = false"
      />
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ConvertToNumber } from '@/utilities/functions/pdf';
import Input from '@/components/form-elements/Input.vue';
import InputPercent from '@/components/form-elements/InputPercent.vue';
import InputCurrency from '@/components/form-elements/InputCurrency.vue';
import Radio from '@/components/form-elements/Radio.vue';
import Button from '@/components/form-elements/Button.vue';
import Datepicker from '@/components/form-elements/Datepicker.vue';
import Dropdown from '@/components/form-elements/Dropdown.vue';
import EquipmentTable from '@/components/form-elements/EquipmentTable.vue';
import GeneratePDF from './GeneratePDF.vue';
import FormAddOnProducts from './FormAddOnProducts.vue';
import FormAddOnProductItem from './FormAddOnProductItem.vue';

export default {
  name: 'capitalCostForm',
  components: {
    Input,
    InputPercent,
    InputCurrency,
    Radio,
    Button,
    Datepicker,
    Dropdown,
    EquipmentTable,
    GeneratePDF,
    FormAddOnProducts,
    FormAddOnProductItem,
  },
  data() {
    return {
      showPDF: false,
      hasSubmitted: false,
      errors: [],

      // Dealer & Customer
      customerName: '',
      offeringNumber: '',

      // Vehicle Information
      vehicle: {
        year: '',
        make: '',
        model: '',
        vin: '',
      },

      // Equipments
      equipments: {
        factoryInvoice: '',
        list: [],
        addEquipExcluded: '',
        capitalizedInitialTitleTax: '',
        capitalizedInitialTitleNoTax: '',
        upFrontTaxesPaidByDealer: '',
        areTaxesCapitalized: '',
      },

      // Up Front
      upFront: {
        localTax: '',
        stateTax: '',
        fetTax: '',
        otherTax: '',
      },

      // Services and Taxes
      taxes: {
        softAddOnProducts: [],
        maintenancePlan: '',
        dropdownMaintenancePlan: {
          label: '',
          value: '',
        },
        serviceContract: '',
        dropdownServiceContract: {
          label: '',
          value: '',
        },
        gap: '',
        dropdownGap: {
          label: '',
          value: '',
        },
        tireWheel: '',
        windshield: '',
        bundledProduct: '',
        vehicleMarkup: '',
        capitalizedCostReduction: '',
        rebates: '',
        tradeAllowance: '',
        tradeOwed: '',
        residual: '',
        customerLeaseRate: '',
        dealerParticipation: '',
        term: '',
        manufacturingRepurchase: '',
        supplementDate: '',
        paymentDueDay: '',
        paymentTiming: {
          label: '',
          value: '',
        },
        vehicleMSRP: '',
        cityTax: '',
        countyTax: '',
        stateTax: '',
        otherTax: '',
      },
      taxesTotalTax: '',
    };
  },
  computed: {
    ...mapGetters({
      customerNameStore: 'capitalCost/customerName',
      offeringNumberStore: 'capitalCost/offeringNumber',
      vehicleStore: 'capitalCost/vehicle',
      equipmentsStore: 'capitalCost/equipments',
      upFrontStore: 'capitalCost/upFront',
      taxesStore: 'capitalCost/taxes',
    }),

    componentText() {
      const sectionNames = 'tabs.capitalCost.sectionNames';
      const sectionVehicle = 'tabs.capitalCost.sectionVehicle';
      const sectionEquipments = 'tabs.capitalCost.sectionEquipments';
      const sectionUpFront = 'tabs.capitalCost.sectionUpFront';
      const sectionTaxes = 'tabs.capitalCost.sectionTaxes';
      const sectionActions = 'tabs.general.sectionActions';

      return {
        customerNameLabel: this.$t(`${sectionNames}.customerNameLabel`),
        offeringNumberLabel: this.$t(`${sectionNames}.offeringNumberLabel`),

        vehicle: {
          title: this.$t(`${sectionVehicle}.title`),
          yearLabel: this.$t(`${sectionVehicle}.yearLabel`),
          makeLabel: this.$t(`${sectionVehicle}.makeLabel`),
          modelLabel: this.$t(`${sectionVehicle}.modelLabel`),
          vinLabel: this.$t(`${sectionVehicle}.vinLabel`),
        },

        equipments: {
          title: this.$t(`${sectionEquipments}.title`),
          factoryInvoice: this.$t(`${sectionEquipments}.factoryInvoice`),
          addEquipExcludedLabel: this.$t(`${sectionEquipments}.addEquipExcludedLabel`),
          capitalizedInitialTitleLabel: this.$t(`${sectionEquipments}.capitalizedInitialTitleLabel`),
          capitalizedInitialTitleInfoTax: this.$t(`${sectionEquipments}.capitalizedInitialTitleInfoTax`),
          capitalizedInitialTitleInfoNoTax: this.$t(`${sectionEquipments}.capitalizedInitialTitleInfoNoTax`),
          upFrontTaxesPaidByDealer: this.$t(`${sectionEquipments}.upFrontTaxesPaidByDealer`),
          areTaxesCapitalized: this.$t(`${sectionEquipments}.areTaxesCapitalized`),
        },

        upFront: {
          title: this.$t(`${sectionUpFront}.title`),
          localTaxLabel: this.$t(`${sectionUpFront}.localTaxLabel`),
          stateTaxLabel: this.$t(`${sectionUpFront}.stateTaxLabel`),
          fetTaxLabel: this.$t(`${sectionUpFront}.fetTaxLabel`),
          otherTaxLabel: this.$t(`${sectionUpFront}.otherTaxLabel`),
        },

        taxes: {
          title: this.$t(`${sectionTaxes}.title`),
          title2: this.$t(`${sectionTaxes}.title2`),
          maintenancePlanLabel: this.$t(`${sectionTaxes}.maintenancePlanLabel`),
          serviceContractLabel: this.$t(`${sectionTaxes}.serviceContractLabel`),
          gapLabel: this.$t(`${sectionTaxes}.gapLabel`),
          dropdownLabel1: this.$t(`${sectionTaxes}.dropdownLabel1`),
          dropdownLabel2: this.$t(`${sectionTaxes}.dropdownLabel2`),
          dropdownLabel3: this.$t(`${sectionTaxes}.dropdownLabel3`),
          dropdownOption1: this.$t(`${sectionTaxes}.dropdownOption1`),
          dropdownOption2: this.$t(`${sectionTaxes}.dropdownOption2`),
          dropdownOption3: this.$t(`${sectionTaxes}.dropdownOption3`),
          vehicleMarkupLabel: this.$t(`${sectionTaxes}.vehicleMarkupLabel`),
          capitalizedCostReductionLabel: this.$t(`${sectionTaxes}.capitalizedCostReductionLabel`),
          rebatesLabel: this.$t(`${sectionTaxes}.rebatesLabel`),
          tradeAllowanceLabel: this.$t(`${sectionTaxes}.tradeAllowanceLabel`),
          tradeOwedLabel: this.$t(`${sectionTaxes}.tradeOwedLabel`),
          residualLabel: this.$t(`${sectionTaxes}.residualLabel`),
          customerLeaseRateLabel: this.$t(`${sectionTaxes}.customerLeaseRateLabel`),
          customerLeaseRateInfo: this.$t(`${sectionTaxes}.customerLeaseRateInfo`),
          dealerParticipationLabel: this.$t(`${sectionTaxes}.dealerParticipationLabel`),
          termLabel: this.$t(`${sectionTaxes}.termLabel`),
          manufacturingRepurchase: this.$t(`${sectionTaxes}.manufacturingRepurchase`),
          supplementDateLabel: this.$t(`${sectionTaxes}.supplementDateLabel`),
          paymentDueDayLabel: this.$t(`${sectionTaxes}.paymentDueDayLabel`),
          paymentTimingLabel: this.$t(`${sectionTaxes}.paymentTimingLabel`),
          paymentTimingOption1: this.$t(`${sectionTaxes}.paymentTimingOption1`),
          paymentTimingOption2: this.$t(`${sectionTaxes}.paymentTimingOption2`),
          vehicleMSRPLabel: this.$t(`${sectionTaxes}.vehicleMSRPLabel`),
          cityTaxLabel: this.$t(`${sectionTaxes}.cityTaxLabel`),
          countyTaxLabel: this.$t(`${sectionTaxes}.countyTaxLabel`),
          stateTaxLabel: this.$t(`${sectionTaxes}.stateTaxLabel`),
          otherTaxLabel: this.$t(`${sectionTaxes}.otherTaxLabel`),
          totalTaxLabel: this.$t(`${sectionTaxes}.totalTaxLabel`),
        },

        buttonGeneratePDF: this.$t(`${sectionActions}.buttonGeneratePDF`),
        buttonClear: this.$t(`${sectionActions}.buttonClear`),
        buttonClearAll: this.$t(`${sectionActions}.buttonClearAll`),
        buttonCloseAndReturn: this.$t(`${sectionActions}.buttonCloseAndReturn`),
      };
    },

    totalTax() {
      const cityTax = ConvertToNumber(this.taxes.cityTax) || 0;
      const stateTax = ConvertToNumber(this.taxes.stateTax) || 0;
      const countyTax = ConvertToNumber(this.taxes.countyTax) || 0;
      const otherTax = ConvertToNumber(this.taxes.otherTax) || 0;
      const total = cityTax + stateTax + countyTax + otherTax;

      return this.formatCurrency(total, '', '%');
    },
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    ...mapActions({
      updatecapitalCost: 'capitalCost/updatecapitalCost',
      updateEffectiveRate: 'effectiveRate/updateEffectiveRate',
      updatePaymentComparison: 'paymentComparison/updatePaymentComparison',
      updateQuickQuote: 'quickQuote/updateQuickQuote',
      clearcapitalCost: 'capitalCost/clearcapitalCost',
      showNotification: 'notifications/showNotification',
      showDefaultErrorForm: 'notifications/showDefaultErrorForm',
      showLoading: 'notifications/showLoading',
      hideLoading: 'notifications/hideLoading',
    }),

    addProduct(product) {
      this.taxes.softAddOnProducts.push({
        product,
        productCost: '',
        productTaxes: { label: '', value: '' },
      });
    },

    /**
     * Controls fields with error
     * @param {Object} field - Field data
     * @param {String} field.name - Name of Field
     * @param {Boolean} field.error - Field has error?
     */
    validateField(field) {
      if (field.error) {
        this.errors.push(field.name);
      } else {
        this.errors = this.errors.filter((e) => e !== field.name);
      }
    },

    /**
     * Get and Set values from store
     */
    populateForm() {
      // Dealer & Customer
      this.customerName = this.customerNameStore;
      this.offeringNumber = this.offeringNumberStore;

      // Vehicle Information
      this.vehicle.year = this.vehicleStore.year;
      this.vehicle.make = this.vehicleStore.make;
      this.vehicle.model = this.vehicleStore.model;
      this.vehicle.vin = this.vehicleStore.vin;

      // Equipments
      const equipments = this.equipmentsStore;
      this.equipments.factoryInvoice = equipments.factoryInvoice;
      this.equipments.list = equipments.list;
      this.equipments.addEquipExcluded = equipments.addEquipExcluded;
      this.equipments.capitalizedInitialTitleTax = equipments.capitalizedInitialTitleTax;
      this.equipments.capitalizedInitialTitleNoTax = equipments.capitalizedInitialTitleNoTax;
      this.equipments.upFrontTaxesPaidByDealer = equipments.upFrontTaxesPaidByDealer;
      this.equipments.areTaxesCapitalized = equipments.areTaxesCapitalized;

      // Up Front
      this.upFront.localTax = this.upFrontStore.localTax;
      this.upFront.stateTax = this.upFrontStore.stateTax;
      this.upFront.fetTax = this.upFrontStore.fetTax;
      this.upFront.otherTax = this.upFrontStore.otherTax;

      // Services and Taxes
      this.taxes.softAddOnProducts = this.taxesStore.softAddOnProducts;
      this.taxes.maintenancePlan = this.taxesStore.maintenancePlan;
      this.taxes.dropdownMaintenancePlan = this.taxesStore.dropdownMaintenancePlan;
      this.taxes.serviceContract = this.taxesStore.serviceContract;
      this.taxes.dropdownServiceContract = this.taxesStore.dropdownServiceContract;
      this.taxes.gap = this.taxesStore.gap;
      this.taxes.dropdownGap = this.taxesStore.dropdownGap;
      this.taxes.tireWheel = this.taxesStore.tireWheel;
      this.taxes.windshield = this.taxesStore.windshield;
      this.taxes.bundledProduct = this.taxesStore.bundledProduct;
      this.taxes.vehicleMarkup = this.taxesStore.vehicleMarkup;
      this.taxes.capitalizedCostReduction = this.taxesStore.capitalizedCostReduction;
      this.taxes.rebates = this.taxesStore.rebates;
      this.taxes.tradeAllowance = this.taxesStore.tradeAllowance;
      this.taxes.tradeOwed = this.taxesStore.tradeOwed;
      this.taxes.residual = this.taxesStore.residual;
      this.taxes.customerLeaseRate = this.taxesStore.customerLeaseRate;
      this.taxes.dealerParticipation = this.taxesStore.dealerParticipation;
      this.taxes.term = this.taxesStore.term;
      this.taxes.manufacturingRepurchase = this.taxesStore.manufacturingRepurchase;
      this.taxes.supplementDate = this.taxesStore.supplementDate;
      this.taxes.paymentDueDay = this.taxesStore.paymentDueDay;
      this.taxes.paymentTiming = this.taxesStore.paymentTiming;
      this.taxes.vehicleMSRP = this.taxesStore.vehicleMSRP;
      this.taxes.cityTax = this.taxesStore.cityTax;
      this.taxes.countyTax = this.taxesStore.countyTax;
      this.taxes.stateTax = this.taxesStore.stateTax;
      this.taxes.otherTax = this.taxesStore.otherTax;
    },

    async generatePDF() {
      this.hasSubmitted = true;
      if (this.errors.length) {
        this.showDefaultErrorForm();
        return;
      }

      this.showLoading();
      if (this.equipments.list.length === 0) {
        this.equipments.list.push(
          {
            description: '',
            value: '',
            hasError: false,
          },
        );
      }
      await this.updatecapitalCost({
        customerName: this.customerName,
        offeringNumber: this.offeringNumber,
        vehicle: this.vehicle,
        equipments: this.equipments,
        upFront: this.upFront,
        taxes: this.taxes,
      });

      await this.updateQuickQuote({
        customerName: this.customerName,
        vehicle: this.vehicle,
        taxes: {
          residual: this.taxes.residual,
          paymentTiming: this.taxes?.paymentTiming ?? {
            label: '',
            value: '',
          },
        },
      });
      await this.updatePaymentComparison({
        customerName: this.customerName,
        vehicle: this.vehicle,
        taxes: { residual: this.taxes.residual },
      });
      await this.updateEffectiveRate({
        customerName: this.customerName,
        vehicle: this.vehicle,
        taxes: { residual: this.taxes.residual },
      });

      this.hideLoading();
      this.showPDF = true;

      // Report action to Application Insights
      const userData = this.$store.getters['user/userData'];
      userData.calculator = 'Capital Cost';
      this.$insights.trackEventBtnPageWithMetaInfo('Capital Cost PDF', userData);
    },
  },
  watch: {
    totalTax(value) {
      this.taxesTotalTax = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.capital-cost {
  &__title {
    margin-bottom: 40px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  .field {
    margin-bottom: fds-rem(40px);
    @include media('>=600px'){
      width: calc(50% - 2rem);
    }
    @include media('>=1024px'){
      width: calc(33.33% - 2rem);
    }
  }

  .radios-flex {
    display: flex;
    align-items: center;
    padding-top: fds-rem(24px);

    &__options {
      display: flex;
    }
  }

  .last-fields {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .field.textarea {
      @include media('>=1024px'){
        width: calc(66.66% - 1rem);
        max-width: fds-rem(1040px);
      }
    }
  }

  .first-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include media('>=1024px'){
      justify-content: flex-start;
      gap: 0 3rem;
    }
  }

  .second-info,
  .third-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
</style>
