<template>
  <div class="add-on-products fds-m--b-3">
    <Dropdown
      v-if="isAddingProduct"
      class="field"
      id="capital-cost-dropdown-products"
      name="capital-cost-dropdown-products"
      label="Soft Add-on Products"
      :options="dropdownOptions"
      :selectedOption="product"
      v-model:value="product"
      :submitted="false"
      @select="addProduct"
      required
    ></Dropdown>
    <Button
      v-else
      id="capital-cost-add-product"
      label="Add product"
      icon="add"
      :disabled="selectedProducts.length >= SOFT_ADD_ON_PRODUCTS.length"
      @onClick="isAddingProduct = true"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/form-elements/Dropdown.vue';
import Button from '@/components/form-elements/Button.vue';

const SOFT_ADD_ON_PRODUCTS = [
  { label: 'Tire & Wheel', value: 'tirewheel' },
  { label: 'Windshield Repair', value: 'windshieldrepair' },
  { label: 'Paintless Dent Repair (PDR)', value: 'paintlessdentrepair' },
  { label: 'Bundled Product', value: 'bundledproduct' },
  { label: 'Vehicle Marking (Etch)', value: 'vehiclemarking' },
  { label: 'Theft Recovery (GPS)', value: 'theftrecovery' },
  { label: 'RentalCare', value: 'rentalcare' },
];

export default {
  name: 'FormAddOnProducts',
  emits: ['addProduct'],
  components: {
    Dropdown,
    Button,
  },
  props: {
    selectedProducts: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      SOFT_ADD_ON_PRODUCTS,
      isAddingProduct: false,
      product: {
        label: '',
        value: '',
      },
    };
  },
  computed: {
    dropdownOptions() {
      const products = this.selectedProducts.map(({ product: { value } }) => value);
      return SOFT_ADD_ON_PRODUCTS.filter(({ value }) => !products.includes(value));
    },
  },
  methods: {
    addProduct(event) {
      if (!event.value) return;

      this.$emit('addProduct', event);
      this.isAddingProduct = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-on-products {
  width: 100%;
  .field {
    margin-bottom: fds-rem(40px);
    @include media('>=600px'){
      width: calc(50% - 2rem);
    }
    @include media('>=1024px'){
      width: calc(33.33% - 2rem);
    }
  }
}
</style>
