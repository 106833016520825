export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    offeringNumberLabel: 'Offering Number',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
  },

  // Equipments
  sectionEquipments: {
    title: 'Added Equipment',
    addEquipExcludedLabel: 'Add’t Equip Excluded From Cost',
    factoryInvoice: 'Factory Invoice',
    capitalizedInitialTitleLabel: 'Capitalized Initial Title, Lic., Reg.',
    capitalizedInitialTitleInfoTax: 'Taxable',
    capitalizedInitialTitleInfoNoTax: 'No Tax',
    upFrontTaxesPaidByDealer: 'Up Front Taxes Paid By Dealer',
    areTaxesCapitalized: 'Are Taxes Capitalized',
  },

  // Up Front
  sectionUpFront: {
    title: 'Up Front Taxes Paid By Customer',
    localTaxLabel: 'Local Tax ($)',
    stateTaxLabel: 'State Tax ($)',
    fetTaxLabel: 'FET Tax ($)',
    otherTaxLabel: 'Other Tax ($)',
  },

  // Services and Taxes
  sectionTaxes: {
    title: 'Services and Taxes',
    title2: '@:tabs.quickQuote.sectionTaxes.title',
    maintenancePlanLabel: 'Maintenance Plan',
    serviceContractLabel: 'Service Contract',
    gapLabel: 'GAP',
    dropdownLabel1: 'Maintenace Plan Taxes',
    dropdownLabel2: 'Service Contract Taxes',
    dropdownLabel3: 'GAP Taxes',
    dropdownOption1: 'Monthly',
    dropdownOption2: 'UpFront',
    dropdownOption3: 'No Tax',
    vehicleMarkupLabel: 'Vehicle Markup',
    capitalizedCostReductionLabel: 'Capitalized Cost Reduction (Cash)',
    rebatesLabel: 'Rebates',
    tradeAllowanceLabel: 'Trade Allowance',
    tradeOwedLabel: 'Trade Owed',
    residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
    customerLeaseRateLabel: 'Customer Lease Rate (%)',
    customerLeaseRateInfo: 'Includes Dealer Participation',
    dealerParticipationLabel: 'Dealer Participation (%)',
    termLabel: 'Term',
    manufacturingRepurchase: 'Manufacturing Repurchase',
    supplementDateLabel: 'Supplement Date',
    paymentDueDayLabel: 'Payment Due Day',
    paymentTimingLabel: '@:tabs.quickQuote.sectionTaxes.paymentTimingLabel',
    paymentTimingOption1: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption1',
    paymentTimingOption2: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption2',
    vehicleMSRPLabel: 'Vehicle MSRP',
    cityTaxLabel: 'City Tax (%)',
    countyTaxLabel: 'County Tax (%)',
    stateTaxLabel: 'State Tax (%)',
    otherTaxLabel: 'Other Tax (%)',
    totalTaxLabel: 'Total Tax (%)',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: 'CommerciaLease Capitalized Cost Worksheet',
    customerNameLabel: '@:tabs.capitalCost.sectionNames.customerNameLabel',
    offeringNumberLabel: '@:tabs.capitalCost.sectionNames.offeringNumberLabel',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
    },

    // Equipments
    sectionEquipments: {
      title: 'Equipments',
      factoryInvoiceLabel: 'Factory invoice of Vehicle',
      addedEquipmentsLabel: 'Added Equipments',
      equipmentsValueLabel: 'Equipments Value',
      totalCostLabel: 'Total Cost Added Equipments',
      totalInvoiceLabel: 'Total Invoice (Vehicle Plus Added Equipment)',
      vehicleMarkupLabel: 'Vehicle Markup',
      additionalEquipmentLabel: 'Additional Equipment Excluded From Total Invoice',
      capitalizedInitialTitleLabel: '@:tabs.capitalCost.sectionEquipments.capitalizedInitialTitleLabel',
    },

    // Up Front Taxes
    sectionUpFront: {
      title: 'Up Front Taxes',
      table: {
        localTax: '@:tabs.capitalCost.sectionUpFront.localTaxLabel',
        stateTax: '@:tabs.capitalCost.sectionUpFront.stateTaxLabel',
        fetTax: '@:tabs.capitalCost.sectionUpFront.fetTaxLabel',
        otherTax: '@:tabs.capitalCost.sectionUpFront.otherTaxLabel',
        totalTax: 'Total Up Front Taxes',
        upFrontTax: '(Capitalized)',
      },
      capitalizedCostLabel: 'Capitalized Cost',
      serviceContractLabel: 'Service Contract',
      warrantyCostLabel: 'Warranty Cost',
      gapLabel: '@:tabs.capitalCost.sectionTaxes.gapLabel',
      capitalizedCostReductionLabel: '@:tabs.capitalCost.sectionTaxes.capitalizedCostReductionLabel',
      paintlessDentRepairLabel: '@:tabs.capitalCost.sectionTaxes.paintlessDentRepairLabel',
      tireWheelLabel: '@:tabs.capitalCost.sectionTaxes.tireWheelLabel',
      windshieldLabel: '@:tabs.capitalCost.sectionTaxes.windshieldLabel',
      bundledProductLabel: '@:tabs.capitalCost.sectionTaxes.bundledProductLabel',
      rebatesLabel: '@:tabs.capitalCost.sectionTaxes.rebatesLabel',
      tradeAllowanceLabel: '@:tabs.capitalCost.sectionTaxes.tradeAllowanceLabel',
      tradeOwedLabel: '@:tabs.capitalCost.sectionTaxes.tradeOwedLabel',
      netTradeAllowanceLabel: 'Net Trade Allowance',
      finalNetCapitalizedCostLabel: 'Final Net Capitalized Cost',

      subtitle: 'Note: Enter Final Net Capitalized Cost as Capitalized Cost on Supplement',
      currentAdvanceCalculationLabel: 'Current Advance Calculation (with add-on)',
      advanceCalculationLabel: 'Advance Calculation (Not to exceed 125% of Total Invoice plus added equipment)',
      assumedResidualPercentLabel: 'Assumed Residual Percent (%)',
      vehicleMSRPLabel: '@:tabs.capitalCost.sectionTaxes.vehicleMSRPLabel',
      table1: {
        leftTitle1: 'Customer Rate',
        leftTitle2: 'Term',
        leftTitle3: 'Supplement Date',
        leftTitle4: 'Commencement Date',
        leftTitle5: 'Payment Due Day',
        leftTitle6: 'Dealer Participation',
        leftTitle7: 'City Tax',
        leftTitle8: 'State Tax',
        leftTitle9: 'County Tax',
        leftTitle10: 'Other Tax',
        leftTitle11: 'Total Tax',
      },
      table2: {
        topTitle1: 'Base Payment (w/o Tax)',
        topTitle2: 'Tax',
        topTitle3: 'Payment Including Tax',
        topTitle4: 'First Payment Scheduled Due Date*',
        topTitle5: 'Manufacturer Repurchase',
        leftSubtitle1: 'Advance Payment',
        leftSubtitle2: 'Arrears Payment',
        information: '* Advance first payments are paid at dealership when executing supplement',
        arrearsAttention: 'Please confirm with the Business Center prior to sending Arrears contracts for funding to ensure appropriate billing dates',
      },
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
    },
  },
};
