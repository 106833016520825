import settings from '@/plugins/pdfSettings';

/**
 * Custom column with label and information side by side
 * @param {String} label Label of the information (Like: Customer Name)
 * @param {String} information Information of column (Like: John Doe)
 * @returns A column with small label and the info
 */
const leftRightColumn = (label, information) => ({
  columns: [
    {
      width: '70%',
      text: label,
      color: settings.variables.colors.black,
      alignment: 'left',
    },
    {
      width: '30%',
      text: information,
      alignment: 'right',
    },
  ],
});

/**
 * Custom line
 * @param {String} info Content of Column
 * @returns Table column custom style
 */
const tableLine = (info, alignment = 'left') => ({
  text: info,
  fontSize: 10,
  color: '#4D4D4D',
  margin: [8, 5, 8, 5],
  alignment,
});

/**
 * Custom header column
 * @param {String} title Title of the Column
 * @param {Object} config Config of the Column (Like: { margin: [15, 15, 5, 5] })
 * @returns Table header column custom style
 */
const tableHeaderColumn = (title, config = {}) => ({
  text: title,
  color: settings.variables.colors.primary,
  alignment: config.alignment || 'center',
  margin: config.margin || [10, 18, 0, 0],
  border: config.border || false,
});

/**
 * Custom column
 * @param {String} info Content of Column
 * @param {Object} config Config of the Column (Like: { border: true })
 * @returns Table column custom style
 */
const tableLineColumn = (info, config = {}) => ({
  text: info,
  fontSize: 10,
  color: config.color || settings.variables.colors.text,
  alignment: config.alignment || 'center',
  margin: config.margin || [0, 12, 0, 0],
  border: config.border || false,
});

export default function (data) {
  const equipmentsLabels = data.labels.equipments;
  const equipmentsInfo = data.info.equipments;

  const upFrontLabels = data.labels.upFront;
  const upFrontInfo = data.info.upFront;

  const tableLabels = upFrontLabels.table2;
  const equipmentsList = equipmentsInfo.list;

  return {
    defaultStyle: settings.defaultStyle,
    pageMargins: settings.pageMargins,
    styles: {
      heading: {
        fontSize: 12,
        color: settings.variables.colors.primary,
        margin: [0, 40, 0, 20],
      },
      marginTable2: {
        margin: [0, 5, 0, 5],
      },
    },

    content: [
      settings.components.header,
      settings.components.title(data.labels.subtitle),
      {
        margin: [0, 0, 0, 12],
        columns: [
          {
            width: '100%',
            layout: {
              defaultBorder: false,
              vLineColor: '#FFFFFF',
            },
            table: {
              widths: ['33%', '33%', '33%'],
              body: [
                [
                  {
                    stack: [
                      {
                        width: 'auto',
                        text: data.labels.customerNameLabel,
                        color: '#000',
                        fontSize: 9,
                        margin: [10, 0, 20, 5],
                      },
                      {
                        width: 'auto',
                        text: data.info.customerName,
                        margin: [10, 0, 20, 0],
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        width: 'auto',
                        text: data.labels.offeringNumberLabel,
                        color: '#000',
                        fontSize: 9,
                        margin: [10, 0, 20, 5],
                      },
                      {
                        width: 'auto',
                        text: data.info.offeringNumber,
                        margin: [10, 0, 20, 0],
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        width: 'auto',
                        text: 'Date',
                        color: '#000',
                        fontSize: 9,
                        margin: [10, 0, 20, 5],
                      },
                      {
                        width: 'auto',
                        text: data.info.date,
                        margin: [10, 0, 20, 0],
                      },
                    ],
                  },
                ],
              ],
            },
          },
        ],
      },
      {
        text: data.labels.vehicle.title,
        style: 'heading',
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          {
            width: '100%',
            layout: {
              defaultBorder: false,
              vLineColor: '#FFFFFF',
            },
            table: {
              body: [
                [
                  {
                    columns: [
                      {
                        width: 'auto',
                        text: data.labels.vehicle.yearLabel,
                        color: '#000',
                        alignment: 'left',
                      },
                      {
                        width: 'auto',
                        text: data.info.vehicle.year,
                        margin: [10, 0, 20, 0],
                        alignment: 'left',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        width: 'auto',
                        text: data.labels.vehicle.makeLabel,
                        color: '#000',
                      },
                      {
                        width: 'auto',
                        text: data.info.vehicle.make,
                        margin: [10, 0, 20, 0],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        width: 'auto',
                        text: data.labels.vehicle.modelLabel,
                        color: '#000',
                      },
                      {
                        width: 'auto',
                        text: data.info.vehicle.model,
                        margin: [10, 0, 20, 0],
                        noWrap: true,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        width: 'auto',
                        text: data.labels.vehicle.vinLabel,
                        color: '#000',
                      },
                      {
                        width: 'auto',
                        text: data.info.vehicle.vin,
                        noWrap: true,
                        margin: [10, 0, 20, 0],
                      },
                    ],
                  },
                ],
              ],
            },
          },
        ],
      },
      {
        text: equipmentsLabels.title,
        style: 'heading',
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(equipmentsLabels.factoryInvoiceLabel, equipmentsInfo.factoryInvoice),
        ],
      },
      {
        columnGap: 20,
        margin: [0, 0, 0, 12],
        fontSize: 8,
        columns: [
          {
            width: '75%',
            text: equipmentsLabels.addedEquipmentsLabel,
            color: '#4D4D4D',
          },
          {
            width: '25%',
            text: equipmentsLabels.equipmentsValueLabel,
            color: '#4D4D4D',
          },
        ],
      },
      {
        margin: [0, 0, 0, 20],
        columns: [
          {
            width: '100%',
            layout: {
              fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
              defaultBorder: false,
              vLineColor: '#FFFFFF',
            },
            table: {
              headerRows: 1,
              widths: ['75%', '25%'],
              body: equipmentsList.map((item) => [
                tableLine(item.description),
                tableLine(item.value),
              ]),
            },
          },
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(equipmentsLabels.totalCostLabel, equipmentsInfo.totalCost),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(equipmentsLabels.totalInvoiceLabel, equipmentsInfo.totalInvoice),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(equipmentsLabels.addEquipmentLabel, equipmentsInfo.addEquipment),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(equipmentsLabels.vehicleMarkupLabel, equipmentsInfo.vehicleMarkup),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(equipmentsLabels.capInitialLabel, equipmentsInfo.capInitial),
        ],
      },
      {
        text: upFrontLabels.title,
        style: 'heading',
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.localTaxLabel, upFrontInfo.table.localTax),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.stateTaxLabel, upFrontInfo.table.stateTax),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.fetTaxLabel, upFrontInfo.table.fetTax),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.otherTaxLabel, upFrontInfo.table.otherTax),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(
            (`${upFrontLabels.totalTaxLabel} ${upFrontLabels.upFrontTaxLabel}`),
            upFrontInfo.table.upFrontTax,
          ),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.cCostLabel, upFrontInfo.cCost),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.serviceCLabel, upFrontInfo.serviceC),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.warrantyCostLabel, upFrontInfo.warrantyCost),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.gapLabel, upFrontInfo.gap),
        ],
      },
      ...upFrontInfo.softAddOnProducts.map((item) => ({
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(item.product.label, item.productCost),
        ],
      })),
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.cCostRLabel, upFrontInfo.cCostR),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.rebatesLabel, upFrontInfo.rebates),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.tradeALabel, upFrontInfo.tradeA),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.tradeOwedLabel, upFrontInfo.tradeOwed),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.netTALabel, upFrontInfo.netTA),
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          {
            width: '80%',
            text: upFrontLabels.finalNetCCostLabel,
            color: settings.variables.colors.black,
            alignment: 'left',
          },
          {
            width: '20%',
            text: upFrontInfo.finalNetCCost,
            alignment: 'right',
          },
        ],
      },
      {
        text: upFrontLabels.subtitle,
        alignment: 'center',
        color: '#00095B',
        fontSize: 9,
        margin: [0, 0, 0, 30],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          {
            width: '75%',
            text: upFrontLabels.advanceCalculationLabel,
            color: settings.variables.colors.black,
            alignment: 'left',
          },
          {
            width: '25%',
            text: upFrontInfo.advanceCalculationValue,
            alignment: 'right',
          },
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          {
            width: '60%',
            text: upFrontLabels.assumedRPLabel,
            color: settings.variables.colors.black,
            alignment: 'left',
          },
          {
            width: '20%',
            text: upFrontInfo.assumedRP,
          },
          {
            width: '20%',
            text: upFrontInfo.assumedRPValue,
            alignment: 'right',
          },
        ],
      },
      {
        margin: [0, 0, 0, 12],
        columns: [
          leftRightColumn(upFrontLabels.vehicleMSRPLabel, upFrontInfo.vehicleMSRP),
        ],
      },
      {
        columnGap: 20,
        columns: [
          {
            width: '60%',
            margin: [0, 0, 0, 12],
            layout: {
              fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
              defaultBorder: false,
              vLineColor: '#FFFFFF',
            },
            table: {
              headerRows: 1,
              widths: ['50%', '20%', '30%'],
              body: [
                [
                  tableLine(upFrontLabels.table1.leftTitle1),
                  '',
                  tableLine(data.info.table1.customerRate, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle2),
                  '',
                  tableLine(data.info.table1.term, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle3),
                  '',
                  tableLine(data.info.table1.supplementDate, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle4),
                  '',
                  tableLine(data.info.table1.commencementDate, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle5),
                  '',
                  tableLine(data.info.table1.paymentDueDay, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle6),
                  tableLine(data.info.table1.dealerParticipationPercent),
                  tableLine(data.info.table1.dealerParticipation, 'right'),
                ],
              ],
            },
          },
          {
            width: '40%',
            margin: [0, 0, 0, 30],
            layout: {
              fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
              defaultBorder: false,
              vLineColor: '#FFFFFF',
            },
            table: {
              headerRows: 1,
              widths: ['60%', '40%'],
              body: [
                [
                  tableLine(upFrontLabels.table1.leftTitle7),
                  tableLine(data.info.table1.cityTax, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle8),
                  tableLine(data.info.table1.stateTax, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle9),
                  tableLine(data.info.table1.countyTax, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle10),
                  tableLine(data.info.table1.otherTax, 'right'),
                ],
                [
                  tableLine(upFrontLabels.table1.leftTitle11),
                  tableLine(data.info.table1.totalTax, 'right'),
                ],
              ],
            },
          },
        ],
      },
      {
        margin: [0, 0, 0, 12],
        layout: {
          fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
          defaultBorder: false,
        },
        table: {
          headerRows: 1,
          widths: ['25%', 'auto', '10%', 'auto', 'auto', 'auto'],
          heights: (row) => (row === 0 ? 50 : 20),
          body: [
            [
              tableHeaderColumn(''),
              tableHeaderColumn(upFrontLabels.table2.topTitle1),
              tableHeaderColumn(upFrontLabels.table2.topTitle2),
              tableHeaderColumn(upFrontLabels.table2.topTitle3),
              tableHeaderColumn(upFrontLabels.table2.topTitle4),
              tableHeaderColumn(upFrontLabels.table2.topTitle5),
            ], data.info.paymentTiming === 'advance' ? [
              tableLineColumn(tableLabels.leftSubtitle1, { margin: [0, 5] }),
              tableLineColumn(data.info.table2.advance.basePayment, { margin: [0, 5] }),
              tableLineColumn(data.info.table2.advance.tax, { margin: [0, 5] }),
              tableLineColumn(data.info.table2.advance.paymentIncluding, { margin: [0, 5] }),
              tableLineColumn(data.info.table2.advance.firstPayment, { margin: [0, 5] }),
              tableLineColumn(data.info.table2.advance.manufacturerRepurchase, { margin: [0, 5] }),
            ]
              : [
                tableLineColumn(tableLabels.leftSubtitle2, { margin: [0, 5] }),
                tableLineColumn(data.info.table2.arrears.basePayment, { margin: [0, 5] }),
                tableLineColumn(data.info.table2.arrears.tax, { margin: [0, 5] }),
                tableLineColumn(data.info.table2.arrears.paymentIncluding, { margin: [0, 5] }),
                tableLineColumn(data.info.table2.arrears.firstPayment, { margin: [0, 5] }),
                tableLineColumn(
                  data.info.table2.arrears.manufacturerRepurchase,
                  { margin: [0, 5] },
                ),
              ],
          ],
        },
      },
      {
        margin: [0, 0, 0, 5],
        text: upFrontLabels.table2.information,
        alignment: 'left',
        color: '#4D4D4D',
        fontSize: '9',
      },
      data.info.paymentTiming === 'arrears'
        ? {
          margin: [0, 5],
          text: upFrontLabels.table2.arrearsAttention,
          alignment: 'left',
          color: settings.variables.colors.danger,
          fontSize: '9',
        }
        : {},
    ],
  };
}
